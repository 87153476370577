/* eslint-disable */
import request from '@/plugins/request'

export const getSchedule = payload => {
	const options = {
		method: 'GET',
		url: 'schedule',
		params: payload
	}

	return request(options)
}

export const getScheduleLesson = (uuid, payload) => {
    const options = {
        method: 'GET',
        url: `schedule/lesson/${uuid}`,
        params: payload
    }

    return request(options)
}
