/* eslint-disable */
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'
import ScheduleLessonHandler from '@/apps/school/components/Schedule/Services/ScheduleLessonHandler'

const handler = class ScheduleDataHandler {

	constructor() {
		this._formatOfPeriod = 'YYYY-MM-DD'
		this._scheduleData = {}
		this._coursesData = []
		this._locationsData = []
		this._markColors = [
			"#00b1f1",
			"#ffc100",
			"#c3b997",
			"#72bd44",
			"#f79745",
			"#0070c1",
			"#e56c0a"
		]
	}

	locale() {
		return store.getters.locale
	}

	firstDayOfWeek() {
		return store.getters.firstDayOfWeek
	}

	updateLocale() {
		moment.updateLocale(this.locale(), {
			week: {
				dow: this.firstDayOfWeek()
			}
		})
	}

	/**
	 * @param {moment} momentInst
	 * @param {Number} offset
	 * @returns {moment}
	 */
	_offset(momentInst, offset) {
		offset = offset ? Number(offset) : 0

		const subtract = offset < 0
		offset = Math.abs(offset)

		if(!subtract) {
			momentInst.add(offset, 'week')
		} else {
			momentInst.subtract(offset, 'week')
		}

		return momentInst
	}

	_startOf(offset) {
		return this._offset(moment().startOf('week'), offset)
	}

	_endOf(offset) {
		return this._offset(moment().endOf('week'), offset)
	}

	begin(offset, format) {
		this.updateLocale()

		format = format ? format : this._formatOfPeriod
		return this._startOf(offset).format(format)
	}

	end(offset, format) {
		this.updateLocale()

		format = format ? format : this._formatOfPeriod
		return this._endOf(offset).format(format)
	}

	/**
	 *
	 * @param {Number} offset
	 * @param {String|null} format
	 * @returns {*[]}
	 */
	weekDays(offset, format) {
        format = format || 'dddd DD.MM.YYYY'

		this.updateLocale()

		const periodData = []
		const begin = this._startOf(offset)

		for (let i = 0; i < 7; i++) {
			periodData.push({
				value: begin.format('YYYY-MM-DD'),
				text: begin.format(format)
			})
			begin.add(1, 'days')
		}

		return periodData
	}

	scheduleHas(day, begin, group) {
		return _.has(this._scheduleData, `${day}.${begin}.${group}`)
	}

	scheduleOf(day, begin, group) {
		const lesson =  _.get(this._scheduleData, `${day}.${begin}.${group}`, null)

        if(lesson) {
            lesson.location = _.find(this._locationsData, ['uuid', lesson.location_uuid])
        }

        return lesson
	}

	_scheduleColMarkShadowStyle(color) {
		return `inset ${color} 2px 0 0 0`
	}

	schedule(offset, groups, callSchedule, cb) {
		const weekDays = this.weekDays(offset)
		const colMinHeight = '88px'
		const oddColor = '#f6f6f6'
		const colDefaultProps = {
			minHeight: colMinHeight,
			height: '100%',
			outlined: true,
			color: 'transparent',
			tile: true,
			class: 'schedule-col pa-2 caption d-flex align-center'
		}

		const grid = []

		for (let i = 0; i < weekDays.length; i++) {
			const rowDay = {
				props: {
					key: `row-day-${i}`,
					class: 'schedule-row schedule-row--day d-flex align-center'
				},
				cols: []
			}

			const colDay = {
				data: weekDays[i],
				component: 'ScheduleColDay',
				props: {
					key: `col-${i}-day`,
					width: '100%',
					...colDefaultProps
				}
			}

			colDay.props.class += ' text-uppercase schedule-col--day justify-center'
			colDay.props.minHeight = 28
			colDay.props.style = {
				boxShadow: this._scheduleColMarkShadowStyle('var(--v-primary-base)')
			}

			rowDay.cols.push(colDay)
			grid.push(rowDay)

			for (let c = 0; c < callSchedule.length; c++) {
				const rowLessons = {
					props: {
						key: `row-${i}-${c}-lessons`,
						class: 'schedule-row schedule-row--lesson d-flex align-center'
					},
					cols: []
				}

				rowLessons.props.color = c % 2 === 0 ? 'while' : oddColor
				rowLessons.props.class += c % 2 === 0 ? ' even' : ' odd'

				const colTime = {
					data: callSchedule[c],
					component: 'ScheduleColCall',
					props: {
						key: `col-${i}-${c}-call`,
						width: groups.length > 1 ? '7%' : '25%',
						...colDefaultProps
					}
				}

				colTime.props.class += ' schedule-col--call justify-center'

				const colTimeMarkColorIndex = c > this._markColors.length - 1 ? 0 : c
				colTime.props.style = {
					boxShadow: this._scheduleColMarkShadowStyle(this._markColors[colTimeMarkColorIndex])
				}

				rowLessons.cols.push(colTime)

				for (let l = 0; l < groups.length; l++) {
					const lessonData = this.scheduleOf(weekDays[i].value, callSchedule[c].begin, _.get(groups[l], 'uuid', null))
					const lessonScopeWidth = 93
					let colWidth = lessonScopeWidth / groups.length

					const colLesson = {
						data: lessonData,
						component: 'ScheduleColLesson',
						props: {
							key: `col-${i}-${l}-lesson`,
							width: groups.length > 1 ? colWidth + '%' : '75%',
							...colDefaultProps,
							hover: true
						},
						on: {}
					}

					colLesson.props.class += ' schedule-col--lesson'
					colLesson.props.class += l % 2 === 0 ? ' even' : ' odd'
					colLesson.on.click = () => {
						const data = new ScheduleLessonHandler({
							lesson: colLesson.data,
							courses: _.filter(this._coursesData, o => _.includes(o.groups, groups[l].uuid)),
							locations: this._locationsData,
							groups: groups,
							group: groups[l],
							callSchedule: callSchedule,
							call: callSchedule[c],
							weekDays: weekDays,
							day: weekDays[i]
						})

						cb(data)
					}

					rowLessons.cols.push(colLesson)
				}

				grid.push(rowLessons)
			}
		}

		return grid

	}

	setScheduleData(data) {
		this._scheduleData = {}

		for (let i = 0; i < data.length; i++) {
			const item = data[i]
			this.setScheduleItem(item)
		}
	}

	setScheduleItem(item) {
		const day = moment(item.begin).format('YYYY-MM-DD')
		const time = moment(item.begin).format('HH:mm')

		item.groups.forEach(group => {
			_.set(this._scheduleData, `${day}.${time}.${group.uuid}`, item)
		});
	}

	unsetScheduleItem(item) {
		const day = moment(item.begin).format('YYYY-MM-DD')
		const time = moment(item.begin).format('HH:mm')

		item.groups.forEach(group => {
			_.unset(this._scheduleData, `${day}.${time}.${group.uuid}`)
		});
	}

	setCoursesData(items) {
		this._coursesData = items
	}

	setLocationsData(items) {
		this._locationsData = items
	}
}

export default handler
