<template>
    <div class="schedule-member">
        <v-card outlined>
            <div class="d-flex align-center justify-space-between pa-4">
                <w-btn-action
                    icon="PREV"
                    left
                    large
                    @click="prevWeek"
                />
                <div class="d-flex flex-column justify-center">
                    <div class="title d-flex align-center justify-center">
                        <w-icon
                            value="CALENDAR"
                            size="20"
                            left
                        />
                        <span>{{ $trans('Schedule') }}</span>
                    </div>
                    <div class="subtitle-2 text-center text-md-left font-weight-bold">
                        <span>{{ begin | dateFormat('DD.MM.YYYY') }}</span>
                        <span> - </span>
                        <span>{{ end | dateFormat('DD.MM.YYYY') }}</span>
                    </div>
                </div>
                <w-btn-action
                    icon="NEXT"
                    large
                    right
                    @click="nextWeek"
                />
            </div>
            <v-card
                v-show="isMobile"
                flat
                tile
                color="#5e56690a"
                class="px-6 py-4"
            >
                <v-select
                    v-model="filterDay"
                    :items="filterItems"
                    dense

                    hide-details
                />
            </v-card>
            <w-fx-table
                :headers="headers"
                :hide-default-header="isMobile"
                :items="rows"
                item-class="pa-0"
                bordered
            >
                <template
                    v-for="headerItem in headers"
                    #[`head.${headerItem.value}`]="{ head }"
                >
                    <component
                        :key="`head-${headerItem.value}`"
                        :is="head.component"
                        :item="head.item"
                    />
                </template>

                <template
                    v-for="headerItem in headers"
                    #[`item.${headerItem.value}`]="{ item }"
                >
                    <component
                        :key="`col-${headerItem.value}`"
                        :is="item[headerItem.value].component"
                        :item="item[headerItem.value].item"
                    />
                </template>
            </w-fx-table>
        </v-card>
    </div>
</template>

<script>
import ScheduleDataHandler from '@apps/school/components/Schedule/Services/ScheduleDataHandler'
import { getSchedule } from '@apps/school/api/schedule'
import mobileBreakpointChecker from '@/mixins/mobileBreakpointChecker'

export default {
    name: 'ScheduleMember',
    mixins: [
        mobileBreakpointChecker
    ],
    components: {
        ScheduleMemberCell: () => import('@apps/school/components/Schedule/ScheduleMember/ScheduleLessonCell'),
        ScheduleCallCell: () => import('@apps/school/components/Schedule/ScheduleMember/ScheduleCallCell'),
        ScheduleCallHead: () => import('@apps/school/components/Schedule/ScheduleMember/ScheduleCallHead'),
        ScheduleDayHead: () => import('@apps/school/components/Schedule/ScheduleMember/ScheduleDayHead')
    },
    computed: {
        begin() {
            return this.service.begin(this.offset)
        },
        end() {
            return this.service.end(this.offset)
        },
        filterKey() {
            return this.$auth.isStudent()
                ? 'group'
                : 'teacher'
        },
        filterValue() {
            return this.$auth.isStudent()
                ? this.$auth.member.group_uuid
                : this.$auth.member.uuid
        },
        filterItems() {
            const weekDays = this.service.weekDays(this.offset, 'dddd, D MMMM')
            const headers = []

            weekDays.forEach((o, index) => {
                headers.push({
                    text: o.text.toUpperCase(),
                    value: 'day' + index
                })
            })

            return headers
        },
        headers() {
            const weekDays = this.service.weekDays(this.offset, 'dddd|DD')
            const headers = []

            headers.push({
                component: 'schedule-call-head',
                item: {},
                text: 'Clock',
                value: 'clock',
                class: 'schedule-clock-head',
                itemClass: 'schedule-call-cell'
            })

            for (let index = 0; index < weekDays.length; index++) {
                const head = weekDays[index]
                const value = 'day' + index

                if (this.isMobile && this.filterDay !== value) {
                    continue
                }

                const text = head.text.split('|')

                headers.push({
                    component: 'schedule-day-head',
                    item: {
                        day: text[0],
                        date: text[1],
                        datetime: head.value,
                        text: text[0]
                    },
                    text: text[0],
                    value: value,
                    class: 'text-center py-2',
                    itemClass: 'schedule-lesson-cell'
                })
            }

            return headers
        },
        callSchedule() {
            return this.$store.getters['school/callSchedule']
        },
        rows() {
            const rows = []

            for (let i = 0; i < this.callSchedule.length; i++) {
                const call = this.callSchedule[i]

                const cell = {
                    clock: {
                        component: 'schedule-call-cell',
                        item: call
                    }
                }

                for (let j = 1; j < this.headers.length; j++) {
                    const head = this.headers[j]

                    if (this.isMobile && this.filterDay !== head.value) {
                        continue
                    }

                    cell[head.value] = {
                        component: 'schedule-member-cell',
                        member: this.memberType,
                        item: this.lessons.find(o => o.begin === `${head.item.datetime} ${call.begin}`)
                    }
                }

                rows.push(cell)
            }

            return rows
        }
    },
    data() {
        return {
            service: new ScheduleDataHandler(),
            lessons: [],
            offset: 0,
            loading: false,
            filterDay: 'day0',
            breadcrumbs: [
                {
                    text: this.$trans('Schedule'),
                    to: { name: 'schedule' }
                }
            ]
        }
    },
    mounted() {
        this.fetchSchedule()
    },
    methods: {
        async fetchSchedule() {
            this.$lprogress.begin()

            try {
                const payload = {
                    begin: this.begin,
                    end: this.end,
                    [this.filterKey]: this.filterValue
                }

                const response = await getSchedule(payload)

                this.lessons = response.data.data
            } catch (error) {

            } finally {
                this.$lprogress.end()
            }
        },
        nextWeek() {
            this.offset++
            this.fetchSchedule()
        },
        prevWeek() {
            this.offset--
            this.fetchSchedule()
        }
    }
}
</script>

<style lang=scss>
.schedule-member {
    .time-head {
        text-align: center;
        width: 10%;
        min-width: 100px;
    }

    .schedule-clock-head,
    .schedule-call-cell {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .schedule-clock-head,
    .schedule-call-cell {
        max-width: 150px;
    }

    .schedule-call-cell,
    .schedule-lesson-cell {
        min-height: 100px;
    }

    .day-head {
        width: calc(90% / 7);
    }
}
</style>
